import React from 'react'
import { navigate } from 'gatsby'
import { useTheme } from 'styled-components'

import SEO from '../components/SEO'
import Header from '../components/Header'
import ArrowUpRight from '../components/icons/ArrowUpRight'

import { TitleS, BodyM } from '../styles/Typography'
import { NotFoundStyled } from '../styles/NotFoundStyled'

const ButikSubscribeSuccess = () => {
  const theme = useTheme()

  return (
    <>
      <SEO title="Successfully Subscribed" />
      <Header textColor={theme.backgroundDark} />
      <NotFoundStyled>
        <TitleS className="title">Thank you for subscribing!</TitleS>
        <button className="btn-back" onClick={() => navigate(-1)}>
          <BodyM className="link" weight={600}>
            <ArrowUpRight />
            <span>back to previous page</span>
          </BodyM>
        </button>
      </NotFoundStyled>
    </>
  )
}

export default ButikSubscribeSuccess
